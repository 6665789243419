import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewRef
} from '@angular/core';
import { ClientBook } from '../../../PODO/clientBook';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { IonContent, ModalController, ToastController } from '@ionic/angular';
import { Attachment, BooksService, RestService } from '../../../services/rest-client/rest-client.service';
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { Subscription, firstValueFrom } from "rxjs";
import moment from "moment";
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { popoverDropdownEnterAnimation } from '../../../../animations/dropdown.animation';

type AttachmentCategory = {
    name: string;
    attachments: Attachment[]
}

@Component({
    selector: 'app-attachment-overview',
    templateUrl: './attachment-overview.component.html',
    styleUrls: ['./attachment-overview.component.scss']
})
export class AttachmentOverviewComponent implements OnInit, OnDestroy {
    public readonly dropdownAnimation = popoverDropdownEnterAnimation;
    private _attachmentLink: string = '';
    public get attachmentLink(): string {
        return this._attachmentLink;
    }

    @ViewChild(IonContent) content: IonContent | undefined;

    @Input() book: ClientBook | undefined;
    @Input() public set attachmentLink(value: string) {
        this._attachmentLink = value;
        if (this.attachmentLink) {
            this.selectedCategory = this.allCategoryString;
        }
        if (this.attachments) {
            this.attachmentsByCategories = this.getAttachmentsByCategory(this.attachments, this.attachmentLink);
        }
        this.attachmentLinkChange.emit(this.attachmentLink);
    }
    @Output() attachmentLinkChange = new EventEmitter();

    selectedCategory: string = "all"
    allCategoryString: string = "all"

    attachments: Attachment[] | undefined;

    private _attachmentsByCategories: AttachmentCategory[] | undefined;
    public get attachmentsByCategories(): AttachmentCategory[] | undefined {
        return this._attachmentsByCategories;
    }
    public set attachmentsByCategories(value: AttachmentCategory[] | undefined) {
        this.ngZone.run(() => this._attachmentsByCategories = value);
    }

    scrollPosition: number = 0;
    public scrollUnlocked: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(
        public bookService: BooksService,
        private restService: RestService,
        public http: HttpClient,
        public sanitizer: DomSanitizer,
        public deviceDetector: DeviceDetectorService,
        public modalCtrl: ModalController,
        private ref: ChangeDetectorRef,
        public screenOrientation: ScreenOrientation,
        public toastCtrl: ToastController,
        public localization: L10nTranslationService,
        private ngZone: NgZone,
        @Inject(L10N_LOCALE) public locale: L10nLocale
    ) {
        // Subscribe to screen orientation changes
        this.subscriptions.push(
            this.screenOrientation.onChange().subscribe(async () => {
                if (!this.deviceDetector.isDesktop()) {
                    this.scrollUnlocked = this.screenOrientation.type === 'landscape'
                        || this.screenOrientation.type === 'landscape-primary'
                        || this.screenOrientation.type === 'landscape-secondary';
                    if (this.content) {
                        await this.content.scrollToPoint(0, this.scrollPosition);
                    }
                }
            })
        );
    }

    ngOnInit(): void {
        if (!this.deviceDetector.isDesktop()) {
            this.scrollUnlocked = this.screenOrientation.type.startsWith('landscape');
        }
        if (this.book?.id) {
            firstValueFrom(this.bookService.getAttachments(this.book?.id)).then
                (
                    (attachmentsList: Attachment[]) => {
                        this.attachments = attachmentsList || [];
                        this.attachmentsByCategories = this.getAttachmentsByCategory(this.attachments, this.attachmentLink);
                    },
                    error => {
                        this.attachments = [];
                    }
                );
        }
    }

    showAllAttachments() {
        this.attachmentLink = '';
    }

    private getAttachmentsByCategory(attachments: Attachment[], attachmentLink?: string): AttachmentCategory[] {
        return attachments.reduce((categories: AttachmentCategory[], attachment: Attachment) => {
            if (
                !attachmentLink || this.isAttachmentLinkedTo(attachment, attachmentLink)
            ) {
                let categoryName = attachment.category;
                const categoryIndex = categories.findIndex((category: AttachmentCategory) => category.name === categoryName);
                if (categoryIndex === -1) {
                    categories.push({
                        name: categoryName,
                        attachments: [attachment]
                    })
                } else {
                    categories[categoryIndex].attachments.push(attachment);
                }
            }
            return categories;
        }, []);
    }

    /**
    * Fetch the attachment data from server and call saveAttachmentFile to save it to device
    * @param Attachment attachment object
    */
    async download(attachment: Attachment) {
        let trimmedUrl = this.trimUrl(attachment.url);
        let attachmentName = this.getAttachmentName(attachment);

        this.restService.downloadFile(trimmedUrl).subscribe({
            next: (event) => {
                if (event.type === HttpEventType.DownloadProgress) {
                    this.notifyChanges();
                } else if (event.type === HttpEventType.Response) {
                    this.notifyChanges();
                    if (event.body) {
                        let blob = event.body;
                        this.saveAttachmentFile(blob, trimmedUrl, attachmentName)
                    }
                }
            },
            error: (error) => {
                console.error('error', error);
            }
        });
    }

    /**
     * Save attachment file to device
     * @param blob Attachment data
     * @param url Link to the attachment file
     * @param attachmentName Name of the attachment file
     * @returns 
     */
    public async saveAttachmentFile(blob: any, url: any, attachmentName: string) {
        // TODO: Check what is going on here
        //IOS SAFARI SOLUTION
        if (!this.safariDetect() && this.deviceDetector.isIOS()) {
            let a = document.createElement("a");
            a.href = url;
            a.download = attachmentName;
            a.target = "_blank"
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
            }, 10);
        } else  {
            saveAs(blob, attachmentName);
        }
    }

    public async isAndroidAndShouldBeDownloaded() {
        let android = false;
        if (!this.deviceDetector.isDesktop() && this.deviceDetector.isAndroid()) {
            android = true;
        }
        return android;
    }

    public getAttachmentName(attachment: Attachment): string {
        let attachmentName = this.getSecPartOfSting(attachment.filename, '#');
        if (attachmentName.indexOf(".") <= 0) {
            let type = this.getSecPartOfSting(attachment.type, '/')
            return attachmentName + "." + type;
        }
        return attachmentName;
    }

    trimUrl(url: any): string {
        let _url = url;
        if (url.indexOf('?') >= 0) {
            _url = url.split('?');
            return _url[0];
        }
        return _url;
    }

    baseToBlob(base64: any, contentType?: string) {
        if (!contentType) contentType = 'application/pdf';
        if (!(base64.indexOf("base65,") >= 0)) {
            base64 = "data:" + contentType + ";base64," + base64;
        }
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: contentType });
    }

    _imageEncode(blob: Blob): Promise<any> {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    toggleCategory(name: any) {
        this.selectedCategory = name;
    }

    public transformDate(date: any) {
        return moment(date).calendar(null, {
            sameDay: 'DD.MM.YYYY',
            nextWeek: 'DD.MM.YYYY',
            lastDay: 'DD.MM.YYYY',
            lastWeek: 'DD.MM.YYYY',
            sameElse: 'DD.MM.YYYY',
        });
    }

    public getSecPartOfSting(str: any, separator: string) {
        let _type = str;
        if (str.indexOf(separator) >= 0) {
            _type = str.split(separator);
            return _type[1];
        }
        return _type;
    }

    formatSizeUnits(bytes: any) {
        if ((bytes >> 30) & 0x3FF)
            bytes = (bytes >>> 30) + '.' + (bytes & (3 * 0x3FF)) + ' GB';
        else if ((bytes >> 20) & 0x3FF)
            bytes = (bytes >>> 20) + '.' + (bytes & (2 * 0x3FF)) + ' MB';
        else if ((bytes >> 10) & 0x3FF)
            bytes = (bytes >>> 10) + '.' + (bytes & (0x3FF)) + ' KB';
        else if ((bytes >> 1) & 0x3FF)
            bytes = (bytes >>> 1) + ' Bytes';
        else
            bytes = bytes + ' Byte';
        return bytes;
    }


    public safariDetect() {
        let browser = (function () {
            let test = function (regexp: any) {
                return regexp.test(window.navigator.userAgent)
            }
            switch (true) {
                case test(/edg/i):
                    return "Microsoft Edge";
                case test(/trident/i):
                    return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i):
                    return "Mozilla Firefox";
                case test(/opr\//i):
                    return "Opera";
                case test(/ucbrowser/i):
                    return "UC Browser";
                case test(/samsungbrowser/i):
                    return "Samsung Browser";
                case test(/chrome|chromium|crios/i):
                    return "Google Chrome";
                case test(/safari/i):
                    return "Apple Safari";
                default:
                    return "Other";
            }
        })();
        return browser === "Apple Safari"
    }

    public notifyChanges() {
        if (!(this.ref as ViewRef)?.destroyed) {
            this.ref.detectChanges();
        }
    }

    onIonScroll(event: any) {
        if (event.detail.scrollTop !== 0) {
            this.scrollPosition = event.detail.scrollTop;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public getAttachmentElementId(attachment: Attachment): string {
        return 'attachment-list-' + decodeURI(attachment.filename);
    }

    public isAttachmentLinkedTo(attachment: Attachment, attachmentLink = this.attachmentLink) {
        return attachment.filename.normalize().toLowerCase() === decodeURI(attachmentLink).normalize().toLowerCase().split('#')[1];
    }
}
