import { Injectable } from '@angular/core';
import { RestService } from '../rest-client/rest-client.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private eventId: number | null;

  constructor(
    private restService: RestService,
    private datePipe: DatePipe
    ) {
    this.eventId = null;
  }

  /**
   * Sends an analytics event to the server
   * @param bookId Id of the opened book
   */
  public async openBook(bookId: number) {
    this.restService.analyticsOpenBook(bookId).subscribe((response: any) => {
      this.eventId = response.id;
    });
  }

  /**
   * Sends the closed book event to the server
   */
  public async updateBookProgress() {
    if (!this.eventId)
      return;

    this.restService.analyticsCloseBook(this.eventId).subscribe((response: any) => {});
  }
}
