export const domains: Record<string, { id: number, theme: string, title: string }> = {
    "www.nationalpark-digital.nrw.de": {
        id: 595,
        theme: "munv",
        title: "umwelt.nrw"
    },
    "www.nationalpark-digital.nrw": {
        id: 595,
        theme: "munv",
        title: "umwelt.nrw"
    }
};

