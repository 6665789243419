import { modules } from './../../PODO/modules';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RestService, Theme, ThemeColor } from '../rest-client/rest-client.service';
import { environment } from '../../../environments/environment';
import {
  TEXT_KEYS,
} from './constants/theme-constants';
import { ReplaySubject } from 'rxjs';

const DEFAULT_TOOLBAR_LOGO_URL = '/assets/icon/lit-header-logo.png';
const DEFAULT_FOOTER_LOGO_URL = '/assets/icon/lit-footer-logo.png';
const DEFAULT_DASHBOARD_HEADER_LOGO_URL = '/assets/icon/lit-logo.png';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  public isInitialized: ReplaySubject<boolean> = new ReplaySubject(1);

  logo = '';
  customerName: string = environment.defaultTheme.name;
  private bookId!: number;
  private icon: string|undefined;
  public modul: modules = {
    registration: 1,
    dashboard: 1,
    profile: 1,
    aboutLitello: 0,
    credits: 1,
    legalNote: 1,
    visitShop: 0,
    DoubleIcons: 0,
    changePassword: 0,
    contactSupport: 0,
  };

  public toolbarLogoUrl: string | SafeUrl = this.getToolbarLogo();
  public footerLogoUrl: string | SafeUrl = this.getFooterLogo();

  constructor(
    private restService: RestService,
    private sanitizer: DomSanitizer,
  ) { }

  /**
   * Injects the theme data into the rendered page
   * @param data Theme data object
   */
  private injectTheme(theme: Theme) {
    this.injectColors(theme.colors);
    this.injectIcon(theme.icon);
    this.injectCss(theme.css);
    document.title = theme.name;
    this.icon = theme.icon;
  }

  /**
   * Load theme through the rest service and inject it into the page.
   * The theme is loaded based on the book id
   */
  public async loadTheme(bookId: number) {
    this.bookId = bookId;
    this.loadThemeData();
  }

  private async loadThemeData() {
    this.restService.getTheme(this.bookId).subscribe({
      next: (theme) => {
        if (theme) {
          console.log("Loaded theme ", theme.name);
          this.injectTheme(theme);
          this.isInitialized.next(true);
        }
        else {
          console.error('No theme exists for this book', this.bookId);
          this.isInitialized.next(true);
        }
      },
      error: () => {
        console.error('No theme exists this book', this.bookId);
        this.isInitialized.next(true);
      }
    });
  }

  /**
   * Get the toolbar logo URL
   * @returns URL of the toolbar logo
   */
  private getToolbarLogo(): SafeUrl | string {
    let headerTopRightLogo = localStorage.getItem('--dashboard-top-right-logo');
    if (headerTopRightLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(headerTopRightLogo);
    } else {
      return DEFAULT_TOOLBAR_LOGO_URL;
    }
  }

  /**
   * Get the default toolbar logo URL
   * @returns Default toolbar logo URL
   */
  public getToolbarDefaultLogo(): string {
    return DEFAULT_TOOLBAR_LOGO_URL;
  }

  /**
   * Get the logo for the dashboard header
   * @param variant of the logo. Allowed values are 2 and 3
   * @returns URL to the header logo
   */
  private getDashboardHeaderLogo(variant?: string): SafeUrl | string {
    // Ensure variant is a string so that it can be concatenated
    if (!variant) {
      variant = "";
    }

    let headerLogo = localStorage.getItem('--dashboard-header-logo' + variant);
    if (headerLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(headerLogo);
    } else {
      return DEFAULT_DASHBOARD_HEADER_LOGO_URL;
    }
  }

  /**
   * Get the URL to the footer logo of the current customer
   * @returns URL to the footer logo
   */
  private getFooterLogo() {
    let footerIcon = localStorage.getItem('--dashboard-footer-icon');
    if (footerIcon) {
      return this.sanitizer.bypassSecurityTrustUrl(footerIcon);
    } else {
      return DEFAULT_FOOTER_LOGO_URL;
    }
  }

  /**
   * Get the default footer logo URL
   * @returns Default footer logo URL
   */
  public getFooterDefaultLogo() {
    return DEFAULT_FOOTER_LOGO_URL;
  }

  /**
   * Inject the theme into the DOM
   * @param colorPalette color palette of the current theme
   */
  public injectColors(colorPalette: ThemeColor[]) {
    for (let color of colorPalette) {
      if (color.key.startsWith('--')) {
        document.documentElement.style.setProperty(color.key, color.value);
      }
      else {
        let propertyKey = '--ion-color-' + color.key;
        document.documentElement.style.setProperty(propertyKey, color.value);
      }
    }
  }

  /**
   * Injects the customer texts into the DOM
   * @param textCollection dictionary of texts
   */
  public injectText(textCollection: any) {
    if (textCollection) {
      TEXT_KEYS.forEach((textKey) => {
        let text = textCollection[textKey];
        if (text) {
          let propertyKey = '--' + textKey;
          localStorage.setItem(propertyKey, text);
        }
      });
    }
  }

  /**
   * Inject CSS into the DOM
   * @param css CSS string to be injected
   */
  public injectCss(css: string) {
    const style = document.createElement('style');
    style.innerHTML = css;
    document.head.appendChild(style);
  }

  /**
   * Inject customer icons into the DOM
   * @param icons logo of the current customer
   */
  public injectIcon(icon: any) {
    let link = document.querySelector("link[rel*='icon']");
    // @ts-ignore
    link = icon;
  }

  public changeTitle(title: any) {
    document.title = title;
  }

  /**
   * Returns the URL of the logo of the customer
   * @param secondary if true, returns the secondary logo which is only used if the double icons module is enabled
   * @returns URL of the logo of the current customer
   */
  public getSideBarLogo(secondary: boolean) {
    if (this.icon) {
      return this.icon;
    } else {
      return '/assets/icon/bookIconYellow.svg'; // default
    }
  }
}
